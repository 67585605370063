import React from 'react'
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { postAppointment } from '../../../actions/appointment';
import { createLoadingSelector } from '../../../reducers/api';
import { renderAppointmentDateTime, parseAppointmentDateTime } from '../../../helpers/date';
import { setOrderInfo } from '../../../actions/orderInfo';
import * as Api from '../../../apis';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CheckoutForm from '../../../components/CheckoutForm';
import Layout from '../../../components/v2/Layout';
import Button from '../../../components/v2/Button';

export default () => {
  const dispatch = useDispatch();
  const { consultantId, slotTimeId, slotTimeText, consultCategoryId, consentPatientInfo } = useSelector(state => state.orderInfo);
  const consultant = useSelector(state => state.appointment.consultantById[consultantId]);
  const category = useSelector(state => state.appointment.categoryById[consultCategoryId]);
  const loading = useSelector(createLoadingSelector(['appointment/post']));
  const [selectedDate, setSelectedDate] = React.useState(null);

  React.useEffect(() => {
    window.logEvent('begin_checkout', { currency: 'THB', value: consultant.price });
    fetchTimeSlot();
  }, []);

  const fetchTimeSlot = async () => {
    const res = await Api.getTimeSlots(consultantId, true);
    const timeSlots = res.results;
    let selected = timeSlots.find(val => val.id === Number(slotTimeId));

    if (!consultant.is_available) {
      if (!selected) { // In case of time gap.
        selected = timeSlots[0];
        dispatch(setOrderInfo({
          slotTimeId: selected.id,
          slotTimeText: renderAppointmentDateTime(parseAppointmentDateTime(selected.date, selected.start_time)),
        }));
      }
    } else {
      const now = new Date();
      selected = now;
      dispatch(setOrderInfo({
        slotTimeText: renderAppointmentDateTime(now),
      }))
    }

    setSelectedDate(selected);
  }

  const checkChange = (e) => {
    dispatch(setOrderInfo({
      consentPatientInfo: e.target.checked
    }))
  }

  const confirm = async () => {
    try {
      const res = await dispatch(postAppointment(true));
      navigate(`/result/appointmentSuccess`, { replace: true });
    } catch (e) {
      alert('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
    }
  }

  return (
    <Layout bg="bg2" title="สรุปนัดหมาย" loading={!consultant || !selectedDate || !category}>
      <Box mb={1}>
        <Typography variant="subtitle1" component="p" color="textSecondary">วันที่ {slotTimeText}</Typography>
        <Typography variant="subtitle1" component="p" color="textSecondary">{consultant?.display_name} แผนก {category?.name}</Typography>
      </Box>
      <Box mb={1}>
        <Alert severity="info">เพื่อเพิ่มคุณภาพการให้บริการครั้งนี้ ในกรณีที่มีการจัดส่งยา(ในกรณีที่มียา) หรือ เรียกรถพยาบาล(ในกรณีที่มีเหตุฉุกเฉิน) กรุณาใส่ข้อมูลของท่านให้ครบถ้วน</Alert>
      </Box>
      <Box mb={1}>
        <Typography variant="h6" component="h1" color="textPrimary">
          รายละเอียด
        </Typography>
        <CheckoutForm title={false} />
      </Box>
      <Box mt={4} display="flex">
        <input onChange={checkChange} type="checkbox" id="consent" name="consent" checked={consentPatientInfo} />
        <label htmlFor="consent">
          <Typography variant="subtitle2" component="p" color="textPrimary">
            ยินยอมให้แพทย์เข้าถึงข้อมูลส่วนตัว ประวัติการใช้บริการ และอื่นๆที่เกี่ยวข้อง เพื่อเป็นประโยชน์ต่อการรักษาของท่าน (Optional)
          </Typography>
        </label>
      </Box>
      <Box mt={1}>
        <Divider />
      </Box>
      <Box mt={3}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={confirm}
        >
          ยืนยัน
        </Button>
      </Box>
    </Layout>
  );
}
